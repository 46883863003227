import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../components/typography.css"

const TermsPageCN = () => {
  return (
    <Layout language={`cn`} lanLink={`/privacy-policy/`}>
      <SEO title={`隐私政策`}/>
      <div className="article">
        <h1>隐私政策</h1>
        <p>
          色采重视网站、产品及服务之用户的隐私，并且尽商业上合理的一切努力保护这些隐私。
          我们承诺将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。我们致力于保护您数据的隐私与安全。
        </p>

        <p>
          色采是一款色彩相关应用产品集，所有功能的目的在于实现色彩的收集、整理、应用，帮助用户在工作中更高效的使用色彩，享受色彩。
          为使用色采软件包括色采和色采时钟（以下简称&quot;色采&quot;）及服务，你应当阅读并遵守本协议。
          本隐私条款为色采相关软件服务条款的一部分，并适用于您使用色采网站、使用或购买我们开发或运营的软件（统称“色采”）、产品和服务。
        </p>

        <p>
          请阅读下文以详细了解我们收集何种信息、我们如何处理这些信息以及我们可能如何使用这些信息。
          您的下载、安装、获取或使用软件及其相关服务，注册或登录等行为代表您阅读并同意本隐私条款的约束，
          包括色采对本隐私条款随时所做的任何修改，您同意我们在遵守此政策以及任何适用的法律的前提下，在必要的范围内收集、使用和披露您的个人信息。
          如果您不同意接受本隐私条款或色采对本隐私条款所做的任何修改，您应立即停止使用色采服务、关闭帐户并卸载软件。
        </p>

        <h2>1.1 我们会收集什么信息？</h2>
        <p>我们将逐一说明我们收集的信息类型以及用途，以便你了解我们开发色采过程中所使用了哪些个人信息。</p>

        <p>当你使用一些功能时，我们会在获得你同意后，获得一些你的信息，比如你在注册账号时，需要填写的用户手机号码和邮箱。</p>

        <p>色采绝不会滥用自己的权限，收集用户额外信息，目前色采获取的所有数据如下：</p>
        <ul>
          <li>
            操作系统、设备型号、接入网络方式、屏幕分辨率：这几项数据作为通用的信息，并无敏感内容，色采只会在网络请求中带上这几类信息，
            方便我们针对不同用户进行适配，维护我们服务的正常运行。
          </li>
          <li>
            邮箱或者手机号：当你在注册色采账号时，需要使用邮箱或者手机号作为认证信息，你提交的邮箱或者手机号，
            我们会以加密的方式存储在国内的阿里云服务器，我们不会将这些信息用于账号以外的其他功能
          </li>
          <li>密码：你账户的密码，我们会以强加密的形式，存储在我们国内的阿里云服务器，别人无法获取，甚至破解，此密码只有你知道。</li>
          <li>
            色卡数据：我们会将你的账单数据，存储在我们国内的阿里云服务器中，所有数据都经过高强度的安全保护，我们只会将你的账单提供给你自己使用，任何第三方都无法获得这些数据。
          </li>
        </ul>

        <p>
          请你理解，以上信息，均是色采为了提供正常服务所必须的，均由你主动提供，我们为你安全存储。
          如果你担心自己的色卡数据安全，可以选择自己删除数据。
          个人信息存储时间仅在你还在使用账号期间，注销账号后我们将不再存储。
          我们为你提供的功能和服务是不断更新和发展的，如果某一项功能需要获取其他信息，我们会提前在官网、版本更新中另行向你说明。
        </p>

        <h2>1.2 第三方SDK的使用</h2>
        <ul>
          <li>
            支付宝SDK用于在色采客户端，调用支付宝客户端完成购买付费功能。
            需要收集您的个人设备信息(包含:硬件型号、设备MAC地址、IMEI、IMSI)、登录IP地址、订单支付相关信息、订单安全相关设备信息及其他反洗钱法律要求的必要信息。
          </li>
          <li>
            微信SDK用于支付、支持用户通过微信客户端来直接注册/登录色采，仅提供 unionid、微信昵称、性别、头像给色采，目的是作为用户唯一标识。
            需要收集您的个人设备信息(包含:硬件型号、设备MAC地址、IMEI、IMSI)、登录IP地址、订单支付相关信息、订单安全相关设备信息及其他反洗钱法律要求的必要信息。
          </li>
        </ul>

        <h2>1.3 Android客户端调用权限与用途说明</h2>
        <ul>
          <li>
            <div>
              联网功能
            </div>
            <div>
              &lt;uses-permission android:name=&quto;android.permission.INTERNET&quto; /&gt;
            </div>
            <div>
              该权限用于App连接网络功能
            </div>
          </li>
          <li>
            <div>
              获取网络状态
            </div>
            <div>
              &lt;uses-permission android:name=&quto;android.permission.ACCESS_NETWORK
              _STATE&quto; /&gt;
            </div>
            <div>
              该权限用于在网络不好时连接到移动网络
            </div>
          </li>
          <li>
            <div>
              获取WIFI状态
            </div>
            <div>
              &lt;uses-permission android:name=&quto;android.permission.ACCESS_WIFI_STATE
              &quto; /&gt;
            </div>
            <div>
              该权限用于App连接网络功能
            </div>
          </li>
          <li>
            <div>
              写入文件
            </div>
            <div>
              &lt;uses-permission android:name=&quto;android.permission.WRITE_EXTERNAL
              _STORAGE&quto; /&gt;
            </div>
            <div>
              该权限用于保存图片、文件、App数据到手机本地
            </div>
          </li>
          <li>
            <div>
              读取文件
            </div>
            <div>
              &lt;uses-permission android:name=&quto;android.permission.READ_EXTERNAL
              _STORAGE&quto; /&gt;
            </div>
            <div>
              该权限用于App相册模块获取手机相册
            </div>
          </li>
          <li>
            <div>
              照相机
            </div>
            <div>
              &lt;uses-permission android:name=&quto;android.permission.CAMERA&quto; /&gt;
            </div>
            <div>
              该权限用于手机实时取色功能
            </div>
          </li>
          <li>
            <div>
              振动权限
            </div>
            <div>
              &lt;uses-permission android:name=&quto;android.permission.VIBRATE&quto; /&gt;
            </div>
            <div>
              该权限用于按钮触控反馈
            </div>
          </li>
          <li>
            <div>
              壁纸权限
            </div>
            <div>
              &lt;uses-permission android:name=&quto;android.permission.SET_WALLPAPER&quto; /&gt;
            </div>
            <div>
              该权限用于设置壁纸功能
            </div>
          </li>
          <li>
            <div>
              安装应用权限
            </div>
            <div>
              &lt;uses-permission android:name=&quto;android.permission.REQUEST_INSTALL
              _PACKAGES&quto; /&gt;
            </div>
            <div>
              该权限用于更新App
            </div>
          </li>
        </ul>

        <p>需要额外说明的是，微信SDK遵循了欧盟GDPR政策，所以上述第三方授权提供的信息，色采也会遵照GDPR进行管理。</p>

        <h2>2、我们如何使用收集的信息？</h2>
        <p>当您许可并通过交易请求获得色采的相关产品及服务时；</p>
        <p>当提供客户服务，需要回应您的电子邮件，文件，问题，意见，要求和投诉时；</p>
        <p>当使用产品过程中，为了让您使用产品更加个性化和带来更好的体验，需要分析使用情况和趋势时；</p>
        <p>当需要改进对您的服务体验、管理和操作时，发送给您的许可证明，产品更新，安全警报，支持和管理信息等。</p>

        <h2>3、信息的披露</h2>
        <p>我们将对信息严格保密，不会与他人或任何机构分享这些信息，亦不会将其出售、出租或以其他方式分发给他人。</p>

        <h2>4、信息的提供</h2>
        <p>
          色采的用户总是可以选择是否披露信息。有些信息是登记我们的服务所必需的，但大多数其他信息的提供是由用户决定的。
          我们将视用户舒适度及自主选择为首位。色采可能为用户提供网络互动活动，为便于用户参予互动活动并获取互动奖品，
          色采通常会需要申请者提供联系方法（通讯地址和电话），此类信息被色采用来累积用户数据。
          如果用户不想提供所要求的信息和参加此类活动，这完全由用户自行决定。
        </p>

        <h2>5、未成年人信息及使用</h2>
        <p>
          色采建议：任何未成年人参加网上活动应事先取得家长或其法定监护人（以下简称&quot;监护人&quot;）的书面同意。
          色采将根据国家相关法律法规的规定保护未成年人的相关信息。
        </p>

        <h2>6、数据安全</h2>
        <p>
          色采承诺在现有技术许可的范围内采取合理必要的预防措施保护用户的数据安全，但是所有的互联网服务商都无法保证数据百分百安全，
          因此色采也无法做出绝对的保证。我们会在合理商业运作的基础上，采取尽可能完备的措施保护您存放在色采中的数据。
          您的个人信息和数据受到多重保护，包括人员的、电子的和程序上的。
        </p>

        <p>如果色采得知安全系统漏洞，我们可能联络您提供给我们的电子邮件地址或在我们的网站上发布通知，以试图告知您并提供关于保护措施（如适用）的信息。</p>

        <h2>7、更新与公开信息</h2>
        <p>
          色采鼓励用户更新和修改其个人信息以使其有效。用户能在任何时候非常容易地获取并修改其个人信息。用户可以自行决定修改、删除他们的相关资料。
          请记住，无论何时您自愿披露个人信息（如在网络上公布）， 此种信息可能被他人收集及使用，因此造成您的个人信息泄露，色采不承担责任，
          如果您将个人信息公布在上述渠道，您就有可能造成个人信息泄露。因此，我们提醒并请您慎重考虑是否有必要在上述渠道公开您的个人信息。
        </p>

        <p>最后，您是唯一对您的账号和密码信息负有保密责任的人。任何情况下，请小心妥善保管。</p>

        <h2>8、我们如何使用请求的应用权限</h2>
        <p>色采请求相机权限用于应用内时时取色功能。</p>
        <p>色采请求存储和相册查看权限用于相册模块的配色识别功能。</p>
        <p>色采请求存储的修改权限用于图片色卡的分享和保存功能。</p>
        <p>色采请求设备信息权限用于注册和登录时的账号认证和绑定功能。</p>

        <h2>9、数据安全与注销</h2>
        <p>
          色采承诺在现有技术许可的范围内采取合理必要的预防措施保护用户的数据安全，我们会在合理商业运作的基础上，采取尽可能完备的措施保护您存放在Todo清单中的数据。
          您的个人信息和数据受到多重保护，包括人员的、电子的和程序上的。
        </p>
        <p>如果色采得知安全系统漏洞，我们可能联络您提供给我们的电子邮件地址或在我们的网站上发布通知，以试图告知您并提供关于保护措施（如适用）的信息。</p>
        <p>
          如您需要注销账户，请登录色采移动端，进入「头像」-「注销」自行删除账户。
          在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，但法律法规另有规定的除外。
        </p>
        <h2>10、修改与更新</h2>
        <p>
          由于本隐私政策可能不时被更新，您应定期查看以了解我们保护所收集之个人信息的方案及程序。您继续使用色采服务即构成您对本隐私政策及其任何更新版本的同意。
          有关本声明或色采的隐私措施的问题请与我们联系。邮箱地址是 support@wizeyes.com
        </p>

        <h2>公司信息</h2>
        <ul>
          <li>公司名称：福州台江区求实贸易有限公司</li>
          {/* <li>公司名称：台江区色彩软件开发工作室</li> */}
          {/* <li>公司注册地址：福建省福州市台江区后洲街道三通路13号中亭街改造嘉和苑连体部分1层127店面</li> */}
          {/* <li>常用办公地址：福建省福州市台江区后洲街道三通路13号中亭街改造嘉和苑连体部分1层127店面</li> */}
          {/* <li>信息保护负责人联系电话：18120807667</li> */}
        </ul>

        <p>更新时间：2022.01.1</p>
      </div>
    </Layout>
  )
}

export default TermsPageCN
